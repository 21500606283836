import {
  AFORO_FIELD_ID,
  ENGANCHE_FIELD_ID,
  IMPORTE_FIELD_ID,
  PAGO_MENSUAL_FIELD_ID,
  PLAZO_FIELD_ID,
  PLAZO_YEARS_FIELD_ID,
  PROPIEDAD_VALOR_FIELD_ID,
} from './fields';

export const YEAR_KEY = 'year';

export const CREDIT_KEYS_NUMBERS = [
  'importe',
  'meses',
  'enganche',
  'year',
  'mensualidad',
  'propietyValue',
];

export const CREDIT_DATA_OBJECT = {
  importe: IMPORTE_FIELD_ID,
  meses: PLAZO_FIELD_ID,
  aforo: AFORO_FIELD_ID,
  enganche: ENGANCHE_FIELD_ID,
  [YEAR_KEY]: PLAZO_YEARS_FIELD_ID,
  plazo: PLAZO_YEARS_FIELD_ID,
  mensualidad: PAGO_MENSUAL_FIELD_ID,
  propietyValue: PROPIEDAD_VALOR_FIELD_ID,
};

export const INFONAVIT_TRADICIONAL_TYPE = 'Tradicional';
export const INFONAVIT_APOYO_INFONAVIT_TYPE = 'Apoyo Infonavit';
export const INFONAVIT_COFINAVIT_TYPE = 'Cofinavit';
export const INFONAVIT_FOVISSSTE_TYPE = 'FOVISSSTE para todos';
export const PROPIEDAD_VALOR_FIELD_NAME = 'propiedadvalor';
export const ENGANCHE_FIELD_NAME = 'enganche';
export const PLAZO_FIELD_NAME = 'plazo';
export const MAX_ABONO_BIMESTRAL_INFONAVIT = 7884.24;
export const DEFAULT_TASA_ANUAL_20_YEARS = 10.5;
export const DEFAULT_PLAZO_20 = 20;
export const DEFAULT_PLAZO_PERFILADOR = '20 años';
export const DEFAULT_PORCENTAJE_ENDEUDAMIENTO = 0.5;
export const DEFAULT_RPI = 0.5;
export const DEFAULT_DECIMALES_REDONDEO = 2;
export const DEFAULT_PORCENTAJE_GASTOS_ESCRITURA = 0.05;
export const DEFAULT_GASTOS_ESCRITURA_PERCENTAGE = 5;
