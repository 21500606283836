import { createInstanceNoToken } from './config';

const BASE_URL = 'page-configs';

export const getPageConfig = async (companyId, pageName) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/company/${companyId}/name/${pageName}`);
  return data;
};

export const getLastPageConfigByEntityAndName = async (entityId, companyId, name) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/entity/${entityId}/company/${companyId}/name/${name}`);
  return data;
};
