import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Typography, Box, Accordion, AccordionSummary, AccordionDetails,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useStyles from './useStyles';
import {
  COMPANY,
  COTIZACION_PROFILING_RECUPERACION,
  ENGANCHE_FIELD_ID,
  ENGANCHE_FIELD_NAME,
  INGRESOS_TOTALES_COACREDITADO_FIELD_NAME,
  INPUT_PLAZO_DEFAULT_CONFIG,
  PERFILAMIENTO_FIELD_NAME,
  PLAZO_FIELD_ID,
  PROPIEDAD_VALOR_FIELD_ID,
  PROPIEDAD_VALOR_FIELD_NAME,
} from '../../../../../constants';
import { useFieldValues } from '../../../hooks/useFieldValues';
import usePerfilador from '../../../../../hooks/usePerfilador';
import { PerfiladorInput } from '../../../../perfilador/components/PerfiladorInput';
import { setInitialPerfiladorPrecioInmueble } from '../../../../../store/perfilador';
import useFetchPageData from '../../../../../hooks/useFetchPageData';
import { getFlowName } from '../../../../../utils/flows';
import { useProduct } from '../../../../../hooks/useProduct';
import useLoading from '../../../../../hooks/useLoading';
import { Loader } from '../../../../../components';
import { getById } from '../../../../../api/unprotected';
import { isEqualOrLessThanValue, isGreaterThanValue } from '../../../../../utils/simulator.utils';
import { convertirANumero } from '../../../../../utils/currency';
import { useMatrizProfilling } from '../../../hooks/useMatrizProfilling';
import { saveExtraDataFieldValues } from '../../../../../utils/fieldValues';

// eslint-disable-next-line no-unused-vars
export const CreditProfilingRecuperation = ({ onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getDefaultProduct } = useProduct();
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    getFieldValueByName,
  } = useFieldValues({ company: COMPANY });
  const {
    fetchLocalCreditData,
    handleMatrizSubmission,
  } = useMatrizProfilling({
    company: COMPANY,
    fetchFieldsConfigsOnLoad: true,
  });
  const { fetchPageData } = useFetchPageData({
    pageName: COTIZACION_PROFILING_RECUPERACION,
    flowName: getFlowName() || getDefaultProduct().name,
    autoFetch: false,
  });

  const {
    inputValue: precio,
    inputNumber: precioNumber,
    enganche,
    engancheNumber,
    plazo,
    plazoNumber,
    onChange,
    onChangeEnganche,
    onChangePlazo,
    storageCurrentCreditData,
  } = usePerfilador({ useDesembolsoMode: true });

  const [matrizResults, setMatrizResults] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [fieldsCoacreditado, setFieldsCoacreditado] = useState([]);

  const fetchCoacreditadoFields = async (idFields) => {
    const fieldsData = await Promise.all(
      idFields.map(async (fieldId) => {
        const fieldData = await getById(fieldId);
        return { ...fieldData, value: '' }; // Initialize value
      }),
    );
    setFieldsCoacreditado(fieldsData);
  };

  const getData = async () => {
    startLoading();
    const data = await getFieldValueByName(PERFILAMIENTO_FIELD_NAME);
    const propiedadValorValue = await getFieldValueByName(PROPIEDAD_VALOR_FIELD_NAME);
    const engancheValue = await getFieldValueByName(ENGANCHE_FIELD_NAME);
    const pageConfig = await fetchPageData();
    const { fieldsRecuperation } = pageConfig;
    const { coacreditado } = fieldsRecuperation;
    await fetchCoacreditadoFields(coacreditado);

    dispatch(
      setInitialPerfiladorPrecioInmueble({
        precio: propiedadValorValue,
        initialEngance: engancheValue,
      }),
    );

    setMatrizResults(data);
    endLoading();
  };

  const handleOnChange = (event) => {
    const { value, name } = event.target;
    setFieldsCoacreditado(
      (prevFields) => prevFields.map(
        (field) => (field.name === name ? { ...field, value } : field),
      ),
    );
  };

  const classesInputs = {
    title: classes.inputLabel,
    input: classes.input,
    inputContainer: classes.inputContainer,
  };

  const isOptionChequed = (optionType) => selectedOptions[optionType];

  const inputsByOption = (option) => {
    const {
      type,
      engancheMaximo = '',
      engancheMinimo = '',
      valorCreditoMaximo = '',
      valorCreditoMinimo = '',
      saldoMinimoCoacreditado = '',
      plazo: plazoRecomendado,
    } = option;

    switch (type) {
      case 'enganche':
        return (
          <PerfiladorInput
            titleLabel="Enganche:"
            name={ENGANCHE_FIELD_NAME}
            type="precio"
            value={enganche}
            onChange={onChangeEnganche}
            isEmpty={engancheNumber <= 0}
            classes={classesInputs}
            validations={[
              (inputValue) => isGreaterThanValue(
                inputValue,
                convertirANumero(engancheMinimo),
                `Enganche no puede ser menor a ${engancheMinimo}`,
              ),
              (inputValue) => isGreaterThanValue(
                convertirANumero(engancheMaximo),
                inputValue,
                `Enganche no puede ser mayor a ${engancheMaximo}`,
              ),
            ]}
            required={isOptionChequed('enganche')}
            hideLabel
          />
        );
      case 'propiedad':
        return (
          <PerfiladorInput
            titleLabel="Propiedad:"
            name={PROPIEDAD_VALOR_FIELD_NAME}
            type="precio"
            value={precio}
            onChange={onChange}
            isEmpty={precioNumber <= 0}
            classes={classesInputs}
            validations={[
              (inputValue) => isGreaterThanValue(
                inputValue,
                convertirANumero(valorCreditoMinimo),
                `Valor no puede ser menor a ${valorCreditoMinimo}`,
              ),
              (inputValue) => isGreaterThanValue(
                convertirANumero(valorCreditoMaximo),
                inputValue,
                `Valor no puede ser mayor a ${valorCreditoMaximo}`,
              ),
            ]}
            required={isOptionChequed('propiedad')}
            hideLabel
          />
        );
      case 'plazo':
        return (
          <PerfiladorInput
            titleLabel={INPUT_PLAZO_DEFAULT_CONFIG.label}
            name={INPUT_PLAZO_DEFAULT_CONFIG.name}
            type="select"
            value={plazo}
            onChange={onChangePlazo}
            options={INPUT_PLAZO_DEFAULT_CONFIG.options}
            required={isOptionChequed('plazo')}
            validations={[
              (inputValue) => isEqualOrLessThanValue(
                plazoRecomendado,
                convertirANumero(inputValue),
                `Plazo no puede ser mayor a ${plazoRecomendado}`,
              ),
            ]}
            hideLabel
          />
        );
      case 'coacreditado':
        return fieldsCoacreditado.map((field) => (
          <PerfiladorInput
            titleLabel={`${field.label}:`}
            key={field.id}
            name={field.name}
            type={field.fieldType?.name === 'currency' ? 'precio' : field.fieldType?.name}
            value={field.value || ''}
            options={field.config?.options}
            onChange={handleOnChange}
            classes={classesInputs}
            validations={
              field.name !== INGRESOS_TOTALES_COACREDITADO_FIELD_NAME
                ? []
                : [
                  (inputValue) => isGreaterThanValue(
                    inputValue,
                    convertirANumero(saldoMinimoCoacreditado),
                    `Ingresos no puede ser menor a ${saldoMinimoCoacreditado}`,
                  ),
                ]
            }
            required
            hideLabel
          />
        ));
      default:
        return null;
    }
  };

  const getFieldsSelected = () => {
    const fieldsArray = [];

    Object.keys(selectedOptions).forEach((optionType) => {
      if (selectedOptions[optionType]) {
        switch (optionType) {
          case 'enganche':
            fieldsArray.push({
              field: ENGANCHE_FIELD_ID,
              value: enganche,
              process: '',
            });
            break;
          case 'propiedad':
            fieldsArray.push({
              field: PROPIEDAD_VALOR_FIELD_ID,
              value: precio,
              process: '',
            });
            break;
          case 'plazo':
            fieldsArray.push({
              field: PLAZO_FIELD_ID,
              value: plazoNumber,
              process: '',
            });
            break;
          case 'coacreditado':
            fieldsCoacreditado.forEach((field) => {
              fieldsArray.push({
                field: field.id || field._id,
                value: field.value,
                process: '',
              });
            });
            break;
          default:
            break;
        }
      }
    });

    return fieldsArray;
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const fieldValues = getFieldsSelected();
      saveExtraDataFieldValues(fieldValues);
      storageCurrentCreditData();
      fetchLocalCreditData();
      await handleMatrizSubmission();
      endLoading();
    } catch (error) {
      endLoading();
      console.log(error);
    }
  };

  const hasSelectedOptions = () => Object.values(selectedOptions).some((option) => option);

  useEffect(() => {
    getData();
  }, []);

  const handleCheckboxChange = (type) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [type]: !prevSelectedOptions[type],
    }));
  };

  const options = matrizResults.productsRecuperationOptions || [];

  return (
    <div className={classes.root}>
      <Container
        component="form"
        onSubmit={handleSubmit}
        disableGutters
        autoComplete="off"
        className={classes.container}
        maxWidth
      >
        <Container disableGutters className={classes.innerContainer}>
          <Loader open={isLoading} />
          <Typography variant="h5" className={classes.title}>
            ¡Parece que no ajusta! Elige una de las opciones para continuar:
          </Typography>
          <Box className={classes.optionsContainer}>
            {options.map((option, index) => (
              <Accordion
                key={option?.title}
                className={classes.accordionItem}
                expanded={!!selectedOptions[option?.type]}
              >
                <AccordionSummary
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Checkbox
                    color="primary"
                    checked={!!selectedOptions[option?.type]}
                    onChange={() => handleCheckboxChange(option?.type)}
                  />
                  <Typography className={classes.titleOption}>{option?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography className={classes.labelInputs}>{option?.label}</Typography>
                  <Box className={classes.inputContainer}>{inputsByOption(option)}</Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!hasSelectedOptions()}
            type="submit"
          >
            Recalcular
          </Button>
          <Typography variant="body2" className={classes.buttonFooterTitle}>
            Proceso gratuito y sin obligación de compra
          </Typography>
        </Container>
      </Container>
    </div>
  );
};

CreditProfilingRecuperation.propTypes = {
  onClick: PropTypes.func,
};

CreditProfilingRecuperation.defaultProps = {
  onClick: () => {},
};
