import * as authAPI from '../../../api/auth';
import * as processAPI from '../../../api/process';
import * as clientsAPI from '../../../api/clients';
import * as fieldValuesAPI from '../../../api/fieldValue';
import * as sharedFieldValuesAPI from '../../../api/sharedFieldValues';

import { AuthError } from '../../../utils';
import { getLeadId } from '../../../utils/leads';
import { getSourceLabel } from '../../../utils/flows';
import { generateSharedFieldValues } from '../../../utils/clients';
import { getRegisterClientFields, storeClientData } from '../../../utils/clientData';
import { generateTMPPasswordField, generateUserDataFromFieldsData } from '../utils/users';

import { LEAD_OWNER_MODEL } from '../constants/leads';
import { createLeadAndNotify } from '../../../utils/api/leads.utils';
import {
  AGENTE_INMOBILIARIO_FIELD_ID,
  COMPANY,
  INICIA_TRAMITE_TIMELINE_TAG,
  LOGIN_PHONE_ID,
  NOMBRE_INMOBILIARIA_FIELD_ID,
} from '../../../constants';
import { completeTimelineTagByTagName } from '../../../api/timeline';
import { getConfigs } from '../../../utils/configs';

export const createProcess = async ({
  flow, client, token, productId, company,
}) => {
  const dataToUpload = {
    flow,
    client,
    fields: [],
    product: productId,
  };
  return processAPI.createProcess(dataToUpload, company, token);
};

export const saveSharedFieldValues = async (fields, clientId, token, ownerModel) => {
  const dataSharedFieldValues = generateSharedFieldValues(fields, clientId, ownerModel);
  await Promise.all(
    dataSharedFieldValues.map(
      async (itemSharedFieldValue) => sharedFieldValuesAPI
        .saveSharedFieldValuesData(
          itemSharedFieldValue,
          token,
        ),
    ),
  );
};

export const createClient = async (dataToUpload) => {
  const clientReponse = await clientsAPI.saveFieldsData(dataToUpload);
  const { client, exists } = clientReponse;
  return { client, exists };
};

export const createClientAccount = () => {

};

export const loginByEmailAndPassword = async (email, password) => {
  try {
    const payloadConfig = await getConfigs(LOGIN_PHONE_ID);
    const { clientRoles = [] } = payloadConfig;
    const { user } = await authAPI.loginByCredentials(email, password, clientRoles);
    return user;
  } catch (e) {
    throw new AuthError();
  }
};

export const storeClientFieldsData = (registerFields, sharedFieldValues) => {
  const item = {
    registerFields,
    sharedFieldValues,
  };
  storeClientData(item);
};

export const createClientAccountFromLocalData = async () => {
  const clietData = getRegisterClientFields();
  const passwordField = generateTMPPasswordField();
  const { value: password } = passwordField;
  const newClientData = {
    ...clietData,
    fields: [...clietData.fields, passwordField],
  };
  const { client } = await createClient(newClientData);
  return { client, password };
};

const addInmobiliaria = (clientData, inmobiliaria) => {
  if (!inmobiliaria) return clientData;
  const newCliente = {
    ...clientData,
    extraData: {
      inmobiliaria,
    },
  };
  return newCliente;
};

export const createLeadFromClientData = async (clientFields, company, inmobiliaria) => {
  const source = getSourceLabel();
  const clientObject = generateUserDataFromFieldsData(clientFields);
  const newClientObject = addInmobiliaria(clientObject, inmobiliaria);
  const createdLead = await createLeadAndNotify({ ...newClientObject, source, company });
  return createdLead;
};

export const saveLeadSharedFieldValues = async (fields, leadId) => {
  await saveSharedFieldValues(fields, leadId, '', LEAD_OWNER_MODEL);
};

export const checkAndSaveLeadSharedData = async (fields) => {
  const leadId = getLeadId();
  if (!leadId) return;
  await saveLeadSharedFieldValues(fields, leadId);
};

export const updateClientByAgentAssigned = async ({ client, agentId, token }) => {
  const { _id } = client;
  const newClient = { ...client, agentAssigned: agentId };
  await clientsAPI.updateClientData(_id, newClient, token);
};

export const updateProcessByAgentAssigned = async ({ process, agentId, token }) => {
  const { _id } = process;
  const newProcess = { ...process, agentAssigned: agentId };
  await processAPI.updateProcess(newProcess, _id, token);
};

export const updateClientDataByAgentAssigned = async ({
  client, agentId, process, token,
}) => {
  if (!agentId) return;
  await updateProcessByAgentAssigned({ process, agentId, token });
  await updateClientByAgentAssigned({ client, agentId, token });
};

export const completeClientIniciaTramiteTag = async (processId, token) => {
  await completeTimelineTagByTagName(
    processId,
    COMPANY,
    { tagName: INICIA_TRAMITE_TIMELINE_TAG },
    token,
  );
};

export const saveFieldValueInmobiliaria = async (value, process, token) => {
  fieldValuesAPI.saveFieldValue({
    field: '6567e60c1f07e5a5c2288d88',
    value,
    process,
  }, token);
};

export const saveFieldValueAgentSelected = async (value, process, token) => {
  fieldValuesAPI.saveFieldValue({
    field: AGENTE_INMOBILIARIO_FIELD_ID,
    value,
    process,
  }, token);
};

export const saveFieldValueNombreInmobiliaria = async (value, process, token) => {
  fieldValuesAPI.saveFieldValue({
    field: NOMBRE_INMOBILIARIA_FIELD_ID,
    value,
    process,
  }, token);
};

export const updateProcessData = async ({ process, token }) => {
  await processAPI.updateProcess(process, process._id, token);
};
