/* eslint-disable react/forbid-prop-types */

import React, {
  memo, useCallback, useMemo, useEffect, useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { optionsFormatter } from './formatter';
import useStyles from './useStyles';
import useEnabler from '../../../hooks/useEnabler';
import { execValidations, getInputLabel } from '../../../utils';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';

const SelectBase = ({
  id,
  name,
  label,
  value,
  required,
  options,
  isEmpty,
  disabled,
  className,
  classNameSelect,
  fullWidth,
  onChange,
  rejections,
  defaultValue,
  hide,
  validations,
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const {
    isEnabled: isRejected,
    enable: enableRejected,
    disable: disableRejected,
  } = useEnabler();

  useEffect(() => {
    if (rejections) {
      enableRejected();
    }
  }, [enableRejected, rejections]);
  const inputRef = useRef();
  const [mErrorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isEmpty) {
      const [input] = inputRef.current.getElementsByTagName('input');
      input.setCustomValidity(ERROR_MESSAGE_EMPTY);
      enableError();
    }
  }, [enableError, isEmpty]);

  const classes = useStyles();

  const optionsEdited = useMemo(() => {
    const newOptions = [];

    if (label) {
      newOptions.push({
        id: 'parent_label',
        name: label,
        value: '',
        disabled: true,
      });
    }

    optionsFormatter(options, newOptions);

    return newOptions;
  }, [options, label]);

  const handleOnChange = useCallback((event) => {
    const { value: _value } = event.target;
    if (rejections) disableRejected();
    const [input] = inputRef.current.getElementsByTagName('input');
    const { isValid, errorMessage } = execValidations(validations, _value);
    if (_value && isValid) {
      input.setCustomValidity('');
      disableError();
      setErrorMessage('');
    } else {
      input.setCustomValidity(errorMessage);
      enableError();
      setErrorMessage(errorMessage);
    }
    onChange(event, _value);
  }, [onChange, validations, execValidations]);

  let errorMessage = isError && (
    <FormHelperText>{mErrorMessage || ERROR_MESSAGE_EMPTY}</FormHelperText>
  );
  errorMessage = isRejected ? (
    <FormHelperText>
      {`${rejections.reason}-${rejections.comments}`}
    </FormHelperText>
  ) : errorMessage;

  useEffect(() => {
    if (!value && defaultValue) {
      handleOnChange({ target: { value: defaultValue } });
    }
  }, [defaultValue]);

  return (
    <FormControl
      className={clsx(className, {
        [classes.hide]: hide,
      })}
      fullWidth={fullWidth}
      variant="filled"
      disabled={disabled}
      ref={inputRef}
      error={isError || isRejected}
    >
      <InputLabel className={classes.InputLabel}>
        {getInputLabel(label, required)}
      </InputLabel>
      <Select
        id={id}
        name={name}
        value={value || defaultValue}
        defaultValue={defaultValue}
        disableUnderline
        onChange={handleOnChange}
        className={classes.Select}
        inputProps={{
          classes: {
            root: clsx(classes.InputPropsRoot, {
              [classes.error]: isError,
              [classNameSelect]: classNameSelect,
            }),
            disabled: classes.InputPropsDisabled,
          },
        }}
      >
        {
          optionsEdited.map((option) => (
            <MenuItem
              key={option.id}
              value={option.value}
              disabled={option.disabled}
            >
              {option.name}
            </MenuItem>
          ))
        }
      </Select>
      {errorMessage}
    </FormControl>
  );
};

SelectBase.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  options: PropTypes.array,
  isEmpty: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  rejections: PropTypes.any,
  defaultValue: PropTypes.string,
  hide: PropTypes.bool,
  classNameSelect: PropTypes.string,
  validations: PropTypes.array,
};

SelectBase.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  required: false,
  options: [],
  isEmpty: undefined,
  disabled: false,
  className: '',
  fullWidth: true,
  onChange: () => { },
  rejections: null,
  defaultValue: '',
  hide: false,
  classNameSelect: '',
  validations: [],
};

export default memo(SelectBase);
