export const isValidObject = (object) => Boolean(object && typeof object === 'object' && Object.keys(object).length > 0);

export const isValidArray = (array) => Boolean(array && Array.isArray(array));

export const isArrayWithItems = (array) => Boolean(isValidArray(array) && array.length > 0);

export const isObject = (object) => Boolean(typeof object === 'object' && object !== null);

export const isEmptyObject = (
  object,
) => Boolean(isObject(object) && Object.keys(object).length === 0);

export const isObjectWithKeys = (
  object,
  keys,
) => Boolean(isObject(object) && keys.every((key) => key in object));
