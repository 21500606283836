import { INPUT_ERROR_MESSAGE_EMPTY } from '../constants';

export const isInputEmpty = (data) => !data || data.length < 1;

export const isNumber = (data) => !Number.isNaN(Number(data));

export const validateRegex = (text, reg) => reg.test(text);

export const getInputLabel = (label, required) => {
  if (!label) return null;
  if (!required) return label;
  return `${label}*`;
};

export const execValidations = (validations, inputValue) => {
  const validationsArray = validations.map((validationFunc) => validationFunc(inputValue));
  const isValidation = validationsArray.filter(({ isValid }) => !isValid);
  const todo = {
    isValid: !isValidation.length,
    errorMessage: isValidation[0]?.errorMessage || INPUT_ERROR_MESSAGE_EMPTY,
  };
  return todo;
};
