// !Remove
/* eslint-disable no-unused-vars */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setActiveNode,
  setHiddenNodes,
  setNodesFields,
  setNodesLoaded,
  setNodesToShow,
} from '../store/nodesFields';
import {
  changeHideChildrensByFieldValue,
  checkIsNodeNotComplete,
  dissableModalDisabledFieldsInNodes,
  filterDocumentFields,
  filterHiddenFields,
  get1DNodesFields,
  getNodeDocumentsFieldsSorted,
  getOrFilterDisabledModalFields,
  getUnhiddenFieldsWithChildren,
  indexFieldsByFieldId,
  mapFieldsToFieldValuesProps,
  markDocumentsHasReversePhoto,
} from '../utils/fields';
import { getProcess, getEmail, isArrayWithItems } from '../utils';
import * as fieldValueAPI from '../api/fieldValue';
import * as processAPI from '../api/process';
import * as flowProcessAPI from '../api/flowProcess';
import { formatFieldValueToStore } from '../utils/fieldValues';
import { fillNodesFieldsFromCustomActions, isFieldToFillFromActions } from '../utils/api/nodes.utils';
import { getFieldValuesSectionsData, storeFieldValuesSectionsData } from '../storage';
import { ERROR_NO_NODES_TO_LOAD } from '../constants';

export const useNodesFields = ({
  initialValues = [],
  applyModalDissabled = false,
  autoStartActiveNode = true,
  separateDocsFields = true,
  startLoading = () => { },
  endLoading = () => { },
}) => {
  const dispatch = useDispatch();
  const [modifiedNode, setModifiedNode] = useState(null);
  const {
    nodesFields, fieldsIndexed, nodesToShow, hiddenNodes, activeNode, nodesLoaded,
  } = useSelector((state) => state.nodesFields);

  const getHiddenNodes = useCallback(
    (nodes) => nodes.filter(({ hideModal = false, data = {} }) => {
      const { config = {} } = data;
      const { hide = false } = config;
      return hide || hideModal;
    }),
    [],
  );

  const filterHiddenNodes = useCallback(
    (nodes) => nodes.filter(({ hideModal = false, data = {} }) => {
      const { config = {} } = data;
      const { hide = false } = config;
      return !hide && !hideModal;
    }),
    [],
  );

  const cleanHideFieldsInNodes = (nodes) => {
    if (Array.isArray(nodes)) {
      return nodes.map((node) => ({ ...node, fields: filterHiddenFields(node.fields) }));
    }
    return { ...nodes, fields: filterHiddenFields(nodes.fields) };
  };

  const startActiveNode = () => {
    dispatch(setActiveNode(0));
  };

  const applyExtraFieldsProps = (fields) => {
    const newFields = markDocumentsHasReversePhoto(fields);
    return newFields;
  };

  const applySortToDisabledModalFields = (fields) => {
    const modalDisabledFields = getOrFilterDisabledModalFields(fields, true);
    const normalFields = getOrFilterDisabledModalFields(fields);
    const sortedFields = [...modalDisabledFields, ...normalFields];
    return sortedFields;
  };

  const applyCleanAndSortToNodesFields = (nodes) => {
    const newNodes = nodes.map((node) => {
      const cleanedHiddenFields = cleanHideFieldsInNodes(node);
      const { fields: nodesDocFieldsSeparated } = separateDocsFields
        ? getNodeDocumentsFieldsSorted(cleanedHiddenFields)
        : cleanedHiddenFields;
      const sortedByDisabledModalFirsts = applySortToDisabledModalFields(nodesDocFieldsSeparated);
      return { ...node, fields: sortedByDisabledModalFirsts };
    });
    return newNodes;
  };

  const fillNodesWithLocalNodeData = (node) => {
    const storedLocalSections = getFieldValuesSectionsData() || {};
    const { fields } = node;
    const storedFields = storedLocalSections[node._id] || [];
    if (!storedFields.length) return fields;

    const newFields = fields.map((field) => {
      const { _id, value = '' } = field;
      const storedField = storedFields.find((sField) => sField._id === _id);
      if (!storedField) return field;
      return { ...field, value: value || storedField.value || '' };
    });
    return newFields;
  };

  const applyNodesFieldsChangesOnFirstLoad = (nodes) => nodes.map((node) => {
    let { fields } = node;
    fields = applySortToDisabledModalFields(fields);
    fields = applyExtraFieldsProps(fields);
    fields = fillNodesWithLocalNodeData({ ...node, fields });
    fields = getUnhiddenFieldsWithChildren(fields);
    return { ...node, fields };
  });

  const loadNodesFields = useCallback((nodeFieldsToLoad, onlyUnhiddenNodes = false) => {
    const nodesToLoad = onlyUnhiddenNodes ? filterHiddenNodes(nodeFieldsToLoad) : nodeFieldsToLoad;
    const nodesToHide = onlyUnhiddenNodes ? getHiddenNodes(nodeFieldsToLoad) : [];
    const newNodes = applyNodesFieldsChangesOnFirstLoad(nodesToLoad);
    const nodesWithDisabledFields = applyModalDissabled
      ? dissableModalDisabledFieldsInNodes(newNodes)
      : newNodes;
    const showNodes = applyCleanAndSortToNodesFields(nodesWithDisabledFields);
    const arrayFields = get1DNodesFields(newNodes);
    const mainFieldsIndexed = indexFieldsByFieldId(arrayFields);
    if (!isArrayWithItems(nodesWithDisabledFields)) throw new Error(ERROR_NO_NODES_TO_LOAD);
    dispatch(
      setNodesFields({
        nodesFields: nodesWithDisabledFields,
        fieldsIndexed: mainFieldsIndexed,
      }),
    );
    dispatch(setHiddenNodes(nodesToHide));
    dispatch(setNodesToShow(showNodes));
    if (autoStartActiveNode) startActiveNode(0);
    dispatch(setNodesLoaded(true));
  }, []);

  const goNextActiveNode = () => {
    if (activeNode === null || activeNode === undefined) {
      dispatch(setActiveNode(0));
      return;
    }
    dispatch(setActiveNode(activeNode + 1));
  };

  const isActiveNodeDocumentNode = () => {
    if (activeNode === null || activeNode === undefined) {
      return false;
    }
    const currentNode = nodesFields[activeNode];
    return currentNode?.fields?.find((field) => field.fieldType.name === 'document');
  };

  const saveFieldValue = async (field, value) => {
    const processId = getProcess();
    const fieldValueFormated = formatFieldValueToStore(field, value, processId);
    await fieldValueAPI.saveFieldValue(fieldValueFormated);
  };

  const updateValueOnDocumentNode = async (field, value) => {
    const isDocumentNode = isActiveNodeDocumentNode();
    if (isDocumentNode && field) await saveFieldValue(field, value);
  };

  const fillFieldsFromActions = async (field, fieldsArray) => {
    if (!field || !isFieldToFillFromActions(field)) return fieldsArray;
    startLoading();

    const fieldsFilled = await fillNodesFieldsFromCustomActions(field, fieldsArray);
    endLoading();
    return fieldsFilled;
  };

  const onChangeFieldValue = async (event, value, position = null, nameEvent = null) => {
    const { name } = event.target;
    const newName = nameEvent || name;
    const currentNodePosition = activeNode || position || 0;
    let fieldEditted = null;
    const newNodes = await Promise.all(nodesFields.map(async (node) => {
      const { fields } = node;
      const newNodeFields = fields.map(
        (field) => (field.name === newName ? { ...field, value } : field),
      );
      fieldEditted = newNodeFields.find((field) => field.name === newName);
      const fieldsUnhide = changeHideChildrensByFieldValue(
        fieldEditted,
        newNodeFields,
        updateValueOnDocumentNode,
      );
      updateValueOnDocumentNode(fieldEditted, value);
      const fieldsFilled = await fillFieldsFromActions(fieldEditted, fieldsUnhide);
      return { ...node, fields: fieldsFilled };
    }));
    dispatch(setNodesFields({ nodesFields: newNodes, fieldsIndexed }));
    setModifiedNode(currentNodePosition);
  };

  const transformNodeFieldsToFieldValues = (processId, node, includeFieldName = false) => {
    const { fields } = node;
    const newFieldValues = mapFieldsToFieldValuesProps({
      fieldsWithValues: fields,
      fieldsIndexed,
      processId,
      includeFieldName,
    });
    return newFieldValues;
  };

  const formatNodeFieldsToBatch = () => {
    const processId = getProcess();
    const fieldsToBatch = nodesFields.reduce((acc, curr) => {
      const fieldValues = transformNodeFieldsToFieldValues(processId, curr, true);
      return [...acc, ...fieldValues];
    }, []);
    return fieldsToBatch;
  };

  const transformAllNodesToUpload = (processId) => {
    const newFieldValues = nodesFields.reduce((acc, curr) => {
      const fieldValues = transformNodeFieldsToFieldValues(processId, curr);
      return [...acc, ...fieldValues];
    }, []);
    return newFieldValues;
  };

  const saveOnlyFields = async (index = null) => {
    const arrayFields = index !== null
      ? nodesFields[index].fields
      : get1DNodesFields(nodesFields);
    const fieldsFiltered = filterDocumentFields(arrayFields);
    const processId = getProcess();
    const fieldValues = mapFieldsToFieldValuesProps({
      fieldsWithValues: fieldsFiltered,
      fieldsIndexed,
      processId,
    });
    await Promise.all(
      fieldValues.map(async (fieldValue) => fieldValueAPI.saveFieldValue(fieldValue)),
    );
  };

  const saveNodeFieldValues = async (index = null, onlyFields = true) => {
    const processId = getProcess();
    if (onlyFields) {
      await saveOnlyFields(index);
      return;
    }

    const newFieldValues = index !== null
      ? transformNodeFieldsToFieldValues(processId, nodesFields[index])
      : transformAllNodesToUpload(processId);

    await Promise.all(
      newFieldValues.map(async (fieldValue) => fieldValueAPI.saveFieldValue(fieldValue)),
    );
  };

  const isActiveNodeNotComplete = () => {
    if (activeNode === null || activeNode === undefined) return true;
    if (!isArrayWithItems(nodesFields)) return true;
    const nodeToCheck = nodesFields[activeNode];
    return checkIsNodeNotComplete(nodeToCheck);
  };

  const sendFieldValuesToBatch = async () => {
    const arrayFieldsToBatch = formatNodeFieldsToBatch();
    await fieldValueAPI.createBatch(arrayFieldsToBatch);
  };

  const sendDocumentEmail = async () => {
    const processId = getProcess();
    await processAPI.sendDocumentEmail(processId);
  };

  const getDescriptionEmail = () => {
    const email = getEmail();
    return `Enviaremos un correo a ${email}, con una liga que podrás abrir desde tu computadora y continuar cargando tus documentos.`;
  };

  const isLastNode = useMemo(() => (
    activeNode >= (nodesToShow.length - 1)
  ), [nodesToShow, activeNode]);

  const fetchNodesAndFieldsByProcess = async (processId, onlyEmptyNodes = false) => {
    if (onlyEmptyNodes) {
      return flowProcessAPI.getOnlyEmptyNodesAndFieldsByProcess(processId);
    }
    return flowProcessAPI.getNodesAndFieldsByProcess(processId);
  };

  const activeNodeFields = useMemo(() => (
    nodesToShow[activeNode]?.fields || []
  ), [nodesToShow, activeNode]);

  const storeCurrentNodeData = () => {
    if (!nodesLoaded || activeNode === null || modifiedNode === null) return;
    const objSectionData = {
      [nodesFields[activeNode]._id]: nodesFields[activeNode]?.fields,
    };
    storeFieldValuesSectionsData(objSectionData);
  };

  useEffect(() => {
    if (!nodesFields.length || modifiedNode === null) return;
    const newNodesToShow = applyCleanAndSortToNodesFields(nodesFields);
    dispatch(setNodesToShow(newNodesToShow));
    setModifiedNode(null);
  }, [nodesFields, modifiedNode]);

  useEffect(() => {
    if (!nodesToShow.length) return;
    storeCurrentNodeData();
  }, [nodesToShow]);

  useEffect(() => {
    if (initialValues.length) {
      dispatch(setNodesFields({ nodesFields: initialValues }));
    }
  });

  return {
    nodesFields,
    nodesToShow,
    nodesLoaded,
    hiddenNodes,
    activeNode,
    isLastNode,
    activeNodeFields,
    fetchNodesAndFieldsByProcess,
    onChangeFieldValue,
    loadNodesFields,
    goNextActiveNode,
    startActiveNode,
    sendDocumentEmail,
    getDescriptionEmail,
    saveNodeFieldValues,
    isActiveNodeNotComplete,
    sendFieldValuesToBatch,
    isActiveNodeDocumentNode,
  };
};
