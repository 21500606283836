/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, {
  useRef, memo, useCallback, useEffect,
} from 'react';
import {
  Box,
  InputLabel, InputBase, InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isValidDigit from './validator';
import { currencyFormatter } from './formatter';
import useStyles from './useStyles';
import useEnabler from '../../../hooks/useEnabler';
import { contieneNumeros, convertirANumero } from '../../../utils/currency';
import { execValidations, getInputLabel } from '../../../utils';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';

const InputPrice = ({
  isFloat,
  id,
  name,
  label,
  value,
  type,
  disabled,
  className,
  classNameLabel,
  fullWidth,
  multiline,
  startAdornment,
  endAdornment,
  onChange,
  onKeyDown,
  helperText,
  hide,
  required,
  isEmpty,
  validations,
  error,
}) => {
  const classes = useStyles();
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const inputRef = useRef(null);
  // false: validado
  // true: no validado
  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target;
    // eslint-disable-next-line no-param-reassign
    event.target.value = _value;
    const { isValid, errorMessage } = execValidations(validations, convertirANumero(_value));
    if (_value && isValid) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(errorMessage);
      enableError();
    }
    const newValue = contieneNumeros(_value) ? _value : '';
    onChange(event, currencyFormatter(newValue, isFloat));
  }, [onChange, isFloat, validations, execValidations]);

  const handleOnKeyDown = useCallback((event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const ctrlCode = event.ctrlKey ? event.ctrlKey : event.metaKey;
    const { value: _value } = event.target;

    if (!isValidDigit(charCode, ctrlCode, _value)) event.preventDefault();
    onKeyDown(event);
  }, []);

  useEffect(() => {
    if (isEmpty) {
      enableError();
      inputRef.current.setCustomValidity(ERROR_MESSAGE_EMPTY);
    } else if (!error.isValid) {
      inputRef.current.setCustomValidity(error.errorMessage);
    } else {
      inputRef.current.setCustomValidity('');
    }
  }, [enableError, isEmpty, error]);

  return (
    <Box variant="standard" className={classes.container}>
      <InputLabel className={clsx(classes.label, classNameLabel)}>
        {getInputLabel(label, required)}
      </InputLabel>
      <InputBase
        inputRef={inputRef}
        placeholder="0"
        hiddenLabel={hide}
        id={id}
        name={name}
        label={label}
        type={type}
        required={required}
        disabled={disabled}
        className={clsx(classes.input, className)}
        fullWidth={fullWidth}
        variant="filled"
        hidden
        multiline={multiline}
        startAdornment={(
          startAdornment
            ? (
              <InputAdornment position="start">
                {startAdornment}
              </InputAdornment>
            )
            : ''
        )}
        endAdornment={(
          endAdornment
            ? (
              <InputAdornment position="end">
                {endAdornment}
              </InputAdornment>
            )
            : ''
        )}
        error={isError}
        helperText={!hide && helperText}
        value={currencyFormatter(value, isFloat)}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />
    </Box>
  );
};

InputPrice.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  isFloat: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.func),
  startAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  helperText: PropTypes.string,
  hide: PropTypes.bool,
  required: PropTypes.bool,
  isEmpty: PropTypes.bool,
  error: PropTypes.object,
};

InputPrice.defaultProps = {
  id: undefined,
  isFloat: false,
  name: undefined,
  label: '',
  value: '',
  type: 'text',
  disabled: false,
  className: '',
  classNameLabel: '',
  fullWidth: true,
  multiline: false,
  startAdornment: '',
  endAdornment: '',
  onChange: () => { },
  onKeyDown: () => { },
  helperText: '',
  hide: false,
  required: false,
  isEmpty: false,
  validations: [],
  error: {
    isValid: false,
    errorMessage: '',
  },
};

export default memo(React.forwardRef(InputPrice));
